<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <div class="app flex-row align-items-center">
        <div class="container">
          <b-row class="justify-content-center">
            <b-col>
              <h1 class="text-center">
                Поздравляем! Вы успешно прошли регистрацию.
              </h1>
              <p class="text-center">
                На Вашу почту, указанную при регистрации, направленно письмо. Для того чтобы перейти на этап верификации воспользуйтесь ссылкой в письме.
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
};
</script>

